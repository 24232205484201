import React, { useState } from 'react'
import { TextField } from '@fluentui/react/lib/TextField'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { MmcCatalogsList } from '../../Shopify/mmcCatalogsList'
import { getAccountStatus, getMmcStoreInfo, getShopifyStoreInfo, UnBindShopify } from '../../../service/shopify.service'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { useNavigate } from 'react-router'
import { FontIcon } from '@fluentui/react/lib/Icon'
import path from '../../../model/path'
import AlertDialog from '../../../component/alertDialog'
import { useBoolean } from '@fluentui/react-hooks'

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Validate Catalogs',
  subText: 'You can delete MMC store when it only contains default and Shopify catalogs'
}

const validateCatalogs = (mmcData) => {
  if (!mmcData || !mmcData.MmcCatalogs) {
    return true
  }
  const mmcCatalogs = mmcData.MmcCatalogs
  const isValidate = mmcCatalogs.every((catalog) => {
    if (catalog.CatalogName.search('ShopifyImport') && catalog.CatalogName.search('default')) {
      return false
    }
    return true
  })
  return isValidate
}

const validateMMCFraud = (mmcData) => {
  return !mmcData?.IsFraud
}

function ToolShopifyUnbindAdsAccount () {
  const [shopDomain, setShopDomain] = useState('')
  const [modalHidden, setModalHidden] = useState(true)
  const [valid, setValid] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [mmcInfo, setMmcInfo] = useState()
  const [unbinding, setUnbinding] = useState(false)
  const [hideAlertDialog, { toggle: toggleHideAlertDialog }] = useBoolean(true)
  const navigate = useNavigate()

  function handleToShopifyInfo () {
    navigate(path.shopify)
  }

  const onExecute = () => {
    setErrorMessages([])
    toggleHideAlertDialog()
  }

  const onUnbind = async () => {
    revertModalDisplay()
    const errorMessagesOnCheck = []
    setValid(false)
    Promise.all([
      getMmcStoreInfo(shopDomain)
        .then(data => {
          setMmcInfo(data)
          let valid = true
          if (!validateCatalogs(data)) {
            errorMessagesOnCheck.push('This Mmc store contains catalogs which is not created from Shopify Sales Channel, we cannot delete it, please inform customer.')
            valid = false
          }
          if (!validateMMCFraud(data)) {
            errorMessagesOnCheck.push('This Mmc store is fraud and cannot be deleted.')
            valid = false
          }
          return valid
        })
        .catch(ex => {
          console.error(ex)
          return false
        }),
      getShopifyStoreInfo(shopDomain)
        .then(data => {
          return true
        })
        .catch(ex => {
          console.error(ex)
          if ((ex?.response?.data?.error?.picApiPoolErrorCode ?? 'UnknownError') === 'ShopifyStoreNotFound') {
            errorMessagesOnCheck.push('This Shopify store is not found or closed, and it cannot be deleted. ')
            return false
          }
          if ((ex?.response?.data?.error?.picApiPoolErrorCode ?? 'UnknownError') === 'ShopifyAccessTokenInvalid') {
            return true
          }
          return false
        })
    ]).then(([mmcValid, shopifyValid]) => {
      setValid(mmcValid && shopifyValid)
      setErrorMessages(errorMessagesOnCheck)
    })
  }

  const onDismiss = () => {
    setModalHidden(!modalHidden)
    setMmcInfo(null)
  }

  const revertModalDisplay = () => {
    setModalHidden(!modalHidden)
  }

  const unbindAccount = async () => {
    try {
      setUnbinding(true)
      const accountData = await getAccountStatus(shopDomain)
      const aid = accountData.AccountID
      const cid = accountData.CustomerID
      await UnBindShopify(shopDomain, cid, aid)
      alert('unbind successful!')
      handleToShopifyInfo()
    } catch (error) {
      setUnbinding(false)
      setErrorMessages([error?.response?.data?.ExceptionMessage ?? 'Something wrong happened.'])
      console.log(error)
    }
  }

  return (
    <div className='main-box' >
      <h1>Microsoft Account Unbind (Shopify)</h1>
      <hr/>
      <div className="card" style={{ marginTop: '24px' }}>
        <h3 style={{ marginTop: '0px', marginBottom: '8px', fontSize: '24px', fontWeight: 800, color: 'red' }}> Attention! </h3>
        <div style={{ fontSize: '20px' }}>
          <li> In which cases you need to use this unbind tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px', fontSize: '20px' }}>
              When a shopify merchant wants to unbind the current ads account and wants to bind another
              ads account under the same microsoft account, you can use this tool to help the shopify merchant unbind.
          </div>
          <li style={{ marginTop: '8px' }}> What happens during unbinding? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px' }}>
              We will delete the MMC store and campaign created by this Shopify merchant in our sales channel, and unbind
              this Shopify domain from their ads account, then reset the sales channel to an unused status.
          </div>
          <li style={{ marginTop: '8px' }}> What you need to pay attention to before using this tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px' }}>
            Please check whether the Mmc store is created from Shopify Sales Channel. Bmc store created from Shopify Sales Channel meet below condition:<br/>
            the merchant&apos;s MMC store onnly contains catalogs whose name is like &quot;ShopifyImport_&lt;market&gt;_&lt;timestamp&gt;&quot; or &quot;Default Catalog&quot;.
            After this, please confirm whether the customer knows that they will lose the existing mmc store and campaign.
          </div>
          <li style={{ marginTop: '8px' }}> How to use this tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px' }}>
            After you have confirmed all the information with the user, please enter the user&apos;s Shopify domain in the text field below, and click Execute.
            Our tool will check for you again whether the customer&apos;s MMC store is created by Shopify&apos;s sales channel.
          </div>
        </div>
      </div>
      <p style={{ fontSize: '24px', marginBottom: '8px', fontWeight: 700 }}> Unbind Tool </p>
      <TextField
        placeholder="Please enter Shopify domain here, like '123.myshopify.com'"
        onChange = { (_, newValue) => { setShopDomain(newValue) }}
      />
      <PrimaryButton text="Execute" onClick={onExecute} style = {{ marginTop: '16px', marginBottom: '16px' }}/>
      <Dialog
        hidden={modalHidden}
        onDismiss={onDismiss}
        dialogContentProps={dialogContentProps}
      >
        {
          mmcInfo === null
            ? <div style={{ marginBottom: '32px' }}>
              <Spinner ariaLive="assertive" size={SpinnerSize.large}/>
            </div>
            : <div>
              <MmcCatalogsList mmcInfo = { mmcInfo }></MmcCatalogsList>
              {
                valid
                  ? <div style={{ display: 'flex' }}>
                    <FontIcon iconName="Completed" style={{
                      fontSize: 24,
                      margin: '8px',
                      height: 24,
                      width: 24,
                      color: 'green'
                    }}/>
                    <p style={{
                      margin: '8px'
                    }}>Pass the Mmc store validation, you can continue to unbind.</p>
                  </div>
                  : errorMessages?.map((message, index) => {
                    return (
                      <div style={{ display: 'flex' }} key={index}>
                        <FontIcon iconName="ErrorBadge" style={{
                          fontSize: 24,
                          margin: '8px',
                          height: 24,
                          width: 24,
                          color: 'red'
                        }}/>
                        <p style={{ margin: '8px' }}> {message} </p>
                      </div>)
                  })
              }
            </div>
        }
        <DialogFooter>
          <PrimaryButton onClick={unbindAccount} disabled={!valid}>
            {
              !unbinding
                ? <p> Unbind </p>
                : <Spinner size={SpinnerSize.small} />
            }
          </PrimaryButton>
          <DefaultButton onClick={onDismiss} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <AlertDialog
        title='Warning'
        content={
          <>
            <span>This operation is irreversible.</span>
            <br/>
            <span>{`Please Confirm you want to unbind the account "${shopDomain}"`}</span>
          </>
        }
        onConfirm={onUnbind}
        hideDialog={hideAlertDialog}
        toggleHideDialog={toggleHideAlertDialog}
      />
    </div>
  )
}

export default ToolShopifyUnbindAdsAccount
