import React, { useState } from 'react'
import { TextField } from '@fluentui/react/lib/TextField'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { getAccountStatus, getMmcStoreInfo, UnBindBigCommerce } from '../../../service/bigcommerce.service'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { FontIcon } from '@fluentui/react/lib/Icon'
import AlertDialog from '../../../component/alertDialog'
import { useBoolean } from '@fluentui/react-hooks'
import { MmcCatalogsList } from '../../BigCommerce/mmcCatalogsList'

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Validate Catalogs',
  subText: 'You can delete MMC store when it only contains default and BigCommerce catalogs'
}

const validateCatalogs = (mmcData) => {
  if (mmcData === null || mmcData.MmcCatalogs === null) {
    return true
  }
  const mmcCatalogs = mmcData.MmcCatalogs
  const isValidate = mmcCatalogs.every((catalog) => {
    if (catalog.CatalogName.search('BigCommerceImport') && catalog.CatalogName.search('default')) {
      return false
    }
    return true
  })
  return isValidate
}

function ToolBigCommerceUnbindAdsAccount () {
  const [shopDomain, setShopDomain] = useState('')
  const [modalHidden, setModalHidden] = useState(true)
  const [catalogsValid, setCatalogsValid] = useState(false)
  const [mmcInfo, setMmcInfo] = useState(null)
  const [unbinding, setUnbinding] = useState(false)
  const [hideAlertDialog, { toggle: toggleHideAlertDialog }] = useBoolean(true)
  const [unbindErrorMessage, setUnbindErrorMessage] = useState('')
  function handleToBigCommerceInfo () {
    // todo navigate to bigcommerce info page
    // navigate(path.BigCommerce)
  }

  const onExecute = () => {
    setUnbindErrorMessage('')
    toggleHideAlertDialog()
  }

  const onUnbind = async () => {
    setModalHidden(!modalHidden)
    const mmcInfoData = await getMmcStoreInfo(shopDomain)
    setMmcInfo(mmcInfoData)
    setCatalogsValid(validateCatalogs(mmcInfoData))
  }

  const onDismiss = () => {
    setModalHidden(!modalHidden)
    setMmcInfo(null)
  }

  const unbindAccount = async () => {
    try {
      setUnbinding(true)
      const accountData = await getAccountStatus(shopDomain)
      const aid = accountData.AccountID
      const cid = accountData.CustomerID
      await UnBindBigCommerce(shopDomain, cid, aid)
      alert('unbind successful!')
      handleToBigCommerceInfo()
    } catch (error) {
      setUnbindErrorMessage((error.response && error.response.data && error.response.data.ExceptionMessage) ?? 'Something wrong happened.')
      console.log(error)
    } finally {
      setUnbinding(false)
      onDismiss()
    }
  }

  return (
    <div className='main-box' >
      <h1>Microsoft Account Unbind (BigCommerce)</h1>
      <hr/>
      <div className="card" style={{ marginTop: '24px' }}>
        <h3 style={{ marginTop: '0px', marginBottom: '8px', fontSize: '24px', fontWeight: 800, color: 'red' }}> Attention! </h3>
        <div style={{ fontSize: '20px' }}>
          <li> In which cases you need to use this unbind tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px', fontSize: '20px' }}>
              When a BigCommerce merchant wants to unbind the current ads account and wants to bind another
              ads account under the same microsoft account, you can use this tool to help the BigCommerce merchant unbind.
          </div>
          <li style={{ marginTop: '8px' }}> What happens during unbinding? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px' }}>
              We will delete the MMC store and campaign created by this BigCommerce merchant in our sales channel, and unbind
              this BigCommerce domain from their ads account, then reset the sales channel to an unused status.
          </div>
          <li style={{ marginTop: '8px' }}> What you need to pay attention to before using this tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px', marginBottom: '16px' }}>
            Please check whether the Mmc store is created from BigCommerce Sales Channel. Bmc store created from BigCommerce Sales Channel meet below condition:<br/>
            the merchant&apos;s MMC store onnly contains catalogs whose name is like &quot;BigCommerceImport_&lt;market&gt;_&lt;timestamp&gt;&quot; or &quot;Default Catalog&quot;.
            After this, please confirm whether the customer knows that they will lose the existing mmc store and campaign.
          </div>
          <li style={{ marginTop: '8px' }}> How to use this tool? </li>
          <div style={{ marginLeft: '32px', marginTop: '8px' }}>
            After you have confirmed all the information with the user, please enter the user&apos;s BigCommerce domain in the text field below, and click Execute.
            Our tool will check for you again whether the customer&apos;s MMC store is created by BigCommerce&apos;s sales channel.
          </div>
        </div>
      </div>
      <p style={{ fontSize: '24px', marginBottom: '8px', fontWeight: 700 }}> Unbind Tool </p>
      <TextField
        placeholder="Please enter BigCommerce storeHash here, like 'a51tvrooik'"
        onChange = { (_, newValue) => { setShopDomain(newValue) }}
      />
      <PrimaryButton text="Execute" onClick={onExecute} style = {{ marginTop: '16px', marginBottom: '16px' }}/>
      <Dialog
        hidden={modalHidden}
        onDismiss={onDismiss}
        dialogContentProps={dialogContentProps}
      >
        {
          mmcInfo === null
            ? <div style={{ marginBottom: '32px' }}>
              <Spinner ariaLive="assertive" size={SpinnerSize.large}/>
            </div>
            : <div>
              <MmcCatalogsList mmcInfo = { mmcInfo } />
              {
                unbindErrorMessage && unbindErrorMessage.length > 0
                  ? <div style={{ display: 'flex' }}>
                    <FontIcon iconName="ErrorBadge" style={{
                      fontSize: 24,
                      margin: '8px',
                      height: 24,
                      width: 24,
                      color: 'red'
                    }}/>
                    <p style={{
                      margin: '8px'
                    }}>{unbindErrorMessage}</p>
                  </div>
                  : catalogsValid
                    ? <div style={{ display: 'flex' }}>
                      <FontIcon iconName="Completed" style={{
                        fontSize: 24,
                        margin: '8px',
                        height: 24,
                        width: 24,
                        color: 'green'
                      }}/>
                      <p style={{
                        margin: '8px'
                      }}>Pass the Mmc store validation, you can continue to unbind.</p>
                    </div>
                    : <div style={{ display: 'flex' }}>
                      <FontIcon iconName="ErrorBadge" style={{
                        fontSize: 24,
                        margin: '8px',
                        height: 24,
                        width: 24,
                        color: 'red'
                      }}/>
                      <p style={{
                        margin: '8px'
                      }}>This Mmc store contains catalogs which is not created from BigCommerce Sales Channel, we cannot delete it, please inform customer.</p>
                    </div>
              }
            </div>
        }
        <DialogFooter>
          <PrimaryButton onClick={unbindAccount} disabled={!catalogsValid} >
            {
              // eslint-disable-next-line no-constant-condition
              !unbinding
                ? <p> Unbind </p>
                : <Spinner size={SpinnerSize.small} />
            }
          </PrimaryButton>
          <DefaultButton onClick={onDismiss} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <AlertDialog
        title='Warning'
        content={
          <>
            <span>This operation is irreversible.</span>
            <br/>
            <span>{`Please Confirm you want to unbind the account "${shopDomain}"`}</span>
          </>
        }
        onConfirm={onUnbind}
        hideDialog={hideAlertDialog}
        toggleHideDialog={toggleHideAlertDialog}
      />
    </div>
  )
}

export default ToolBigCommerceUnbindAdsAccount
